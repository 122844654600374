@if (opened) {
  <div
    class="fixed inset-0 z-40 flex xl:hidden"
    role="dialog"
    aria-modal="true"
    @ngIfAnimation
    [ngClass]="{
      'top-[3rem] h-[calc(100%-3rem)]': hasBanner(),
      'h-full': !hasBanner(),
    }"
  >
    <!-- (touchmove)="touchMove($event)" -->
    <!--
      Off-canvas menu overlay, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div
      class="cdk-overlay-dark-backdrop layer1 fixed inset-0 h-screen"
      aria-hidden="true"
      (click)="closeSidebar.emit()"
    ></div>
    <!-- @ngIfOpacity75 -->
    <!-- [ngClass]="hideMenu ? 'opacity-0' : 'opacity-75'" -->
    <!--
      Off-canvas menu, show/hide based on off-canvas menu state.

      Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
        To: "translate-x-0"
      Leaving: "transition ease-in-out duration-300 transform"
        From: "translate-x-0"
        To: "-translate-x-full"
    -->
    <div
      class="menu-sidebar relative flex w-full max-w-xs flex-1 flex-col pb-4"
      @ngIfTransformXInverse
    >
      <!-- [class.-translate-x-full]="hideMenu" -->
      <!--
        Close button, show/hide based on off-canvas menu state.

        Entering: "ease-in-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in-out duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div class="absolute right-0 top-0 -mr-12 pt-2">
        <button
          type="button"
          class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          (click)="closeSidebar.emit()"
        >
          <span class="sr-only">Close sidebar</span>
          <!-- Heroicon name: outline/x -->
          <svg
            class="h-6 w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div class="h-0 flex-1 overflow-y-auto">
        <nav class="h-full space-y-1">
          <ng-content></ng-content>
        </nav>
      </div>
    </div>

    <div class="w-14 flex-shrink-0" aria-hidden="true">
      <!-- Dummy element to force sidebar to shrink to fit close icon -->
    </div>
  </div>
}
